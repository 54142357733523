<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <div class="flex-c-c">
        <el-input
          v-model="companyName"
          class="mr-16 d-flex align-items-center"
          placeholder="请输入企业名称"
        ></el-input>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="
            pageNum = 1;
            getTableData();
          "
          >查询</el-button
        >
      </div>
      <div>
        <el-button class="v-primary ml10" type="primary" @click="onAdd"
          >申请代理</el-button
        >
      </div>
    </div>
    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="pageNum"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="companyId" align="center" label="企业ID" />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column align="center" prop="companyLogo" label="企业logo">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.companyLogo"
              fit="contain"
              :preview-src-list="[scope.row.companyLogo]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="appStatus" align="center" label="状态">
          <template slot-scope="scope"
            ><span>
              {{ scope.row.appStatus | getStatus }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="appTime" align="center" label="申请时间"  :formatter="timeFormatter"></el-table-column>
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope">
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="del(scope.row)"
              icon="el-icon-info"
              icon-color="red"
              title="确认解除关系？"
            >
              <el-button
                type="danger"
                size="mini"
                class="ma ml10 mb10"
                slot="reference"
                plain
                >解除关系</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <!-- 申请代理 -->
    <el-dialog
      title="申请代理"
      width="500px"
      :modal-append-to-body="false"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item prop="selectId" label="企业名称">
          <el-select
            v-model="form.selectId"
            filterable
            remote
            :loading="remoteLoading"
            :remote-method="remoteMethod"
            placeholder="请输入企业名称搜索"
            style="width: 380px"
          >
            <el-option
              v-for="item in selectList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btncss" @click="submitData()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  addOrApply,
  getDownList,
  getUpList,
  removeAgent,
  searchCompany,
} from "@/api/agentManage.js";
// import { getDataTimeSec } from "@/utils/index.js";

export default {
  components: {
    commonTable,
    customPopconfirm,
  },
  data() {
    return {
      selfCompanyId: "",
      selfUserId: "",
      companyName: "",
      tableData: [],
      total: 0, //总条数
      // currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中

      dialogFormVisible: false,
      form: {
        selectId: "",
      },
      rules: {
        selectId: { required: true, message: "请选择企业", trigger: "change" },
      },
      selectList: [],
      remoteLoading: false,
    };
  },

  created() {
    let info = localStorage.getItem("info");
    if (info) {
      info = JSON.parse(info);
      this.selfCompanyId = info.companyId || "";
      this.selfUserId = info.userId || "";
    }
    this.getTableData();
  },
  filters: {
    getStatus(e) {
      return e == 0
        ? "已申请"
        : e == 1
        ? "已通过"
        : e == 2
        ? "已拒绝"
        : "已取消";
    },
  },
  methods: {
    //请求预约数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyName: this.companyName,
        appCompanyId: this.selfCompanyId,
        // appStatus: 1,
        pageQuery: true,
      };
      getUpList(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list || [];
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
        });
    },
    //添加代理
    onAdd() {
      this.dialogFormVisible = true;
      this.form.selectId = "";
    },
    //搜索企业
    remoteMethod(companyName) {
      this.remoteLoading = true;
      searchCompany({ companyName })
        .then((res) => {
          this.remoteLoading = false;
          this.selectList = res.data ? res.data : [];
        })
        .catch((res) => {
          this.remoteLoading = false;
        });
    },
    //保存
    submitData() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            appCompanyId: this.selfCompanyId,
            agentCompanyId: this.form.selectId,
            userId: this.selfUserId,
            applyType: 0,
          };
          addOrApply(params)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(res.message);
                this.dialogFormVisible = false;
                this.pageNum = 1;
                this.getTableData();
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      });
    },
    //解除关系
    del(row) {
      removeAgent({ appId: row.appId })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message);
            this.pageNum = 1;
            this.getTableData();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
     timeFormatter(e) {
      return e.appTime.slice(0, 10) + " " + e.appTime.slice(11, 19);
    },
    //详情页
    // detail_tap(companyId) {
    //   // this.$router.push("/companyDetail?id=" + companyId);
    // },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      // this.currentPage = 1;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>