<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="下级代理企业" name="first">
        <downAgent
          v-if="activeName == 'first'"
          :jurisdiction="jurisdiction"
        />
      </el-tab-pane>

      <el-tab-pane label="上级代理企业" name="second">
        <upAgent
          v-if="activeName == 'second'"
          :jurisdiction="jurisdiction"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import downAgent from "./components/downAgent.vue";
import upAgent from "./components/upAgent.vue";
// import { mapState } from "vuex";

export default {
  name: "management",
  components: {
    downAgent,
    upAgent,
  },

  data() {
    return {
      activeName: "first",
      jurisdiction: false,
    };
  },
  computed: {
    // ...mapState(["routerArr"]),
  },
  created() {
    if (this.$route.query.name) {
      this.activeName = this.$route.query.name;
    }
  },
  watch: {
    // routerArr(val) {
    //   // console.log('----------',val);
    //   this.jurisdiction = val.includes("040");
    //   // console.log(this.jurisdiction, "this.jurisdiction");
    // },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
