import httpUtil from "@/utils/httpUtil";

// export const selectAgentCompanyList = params => httpUtil.post("/api/crmPc/companyGoods/selectAgentCompanyList", params);
// export const getAdminCompanyInfo = params => httpUtil.post("/api/crmPc/companyGoods/getAdminCompanyInfo", params);

//添加、申请代理
export const addOrApply = params => httpUtil.post("/api/crmPc/company/applyAgentCompany", params);
//下级代理
export const getDownList = params => httpUtil.post("/api/crmPc/company/agentSelfCompanies", params);
//上级代理列表
export const getUpList = params => httpUtil.post("/api/crmPc/company/selfAgentCompanies", params);
//解除
export const removeAgent = params => httpUtil.post("/api/crmPc/company/removeAgentRelation", params);

//搜索公司

export const searchCompany = params => httpUtil.post("/api/crmPc/company/list", params);
